<template>
  <van-tab :title="title" v-bind="$attrs">
    <TaskDetailTabContent :id="id" :task-detail="taskDetail"></TaskDetailTabContent>
  </van-tab>
</template>

<script>
import TaskDetailTabContent from './TaskDetailTabContent';

export default {
  name: "TaskDetailTab",
  components: {TaskDetailTabContent},
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    taskDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>

<style scoped lang="less">

</style>
