<template>
  <div class="task-detail">
    <global-header title="验货任务详情"/>
    <tab-page>
      <task-sample-tab title="点数抽样" name="sample" :id="taskId" :task-detail="taskDetail"></task-sample-tab>
<!--      <task-info-tab title="基础信息" name="basic" :id="taskId" :task-detail="taskDetail"/>-->
      <task-detail-tab title="验货任务" name="task" :id="taskId" :task-detail="taskDetail"/>
      <task-report-tab title="验货报告" name="report" :id="taskId" :task-detail="taskDetail" @on-refresh="getTaskDetail"/>
    </tab-page>
  </div>
</template>

<script>
import GlobalHeader from '@/components/Common/GlobalHeader.vue'
import TabPage from '@/components/Common/TabPage.vue'
import TaskSampleTab from '@/components/TaskDetail/TaskSampleTab.vue'
// import TaskInfoTab from '@/components/TaskDetail/TaskInfoTab.vue'
import TaskDetailTab from '@/components/TaskDetail/TaskDetailTab.vue'
import TaskReportTab from '@/components/TaskDetail/TaskReportTab.vue'
import {getAction} from '@/api/manage';
import inobounce from 'inobounce';

export default {
  name: 'taskDetail',
  components: {TaskSampleTab, TaskDetailTab,TaskReportTab, TabPage, GlobalHeader},
  data() {
    return {
      taskId: this.$route.query.taskId,
      taskDetail: {}
    }
  },
  created() {
    this.getTaskDetail()
  },
  mounted() {
    let u = navigator.userAgent
    if (u.indexOf('iPhone') > -1) {
      inobounce.enable()
    }
  },
  beforeDestroy() {
    inobounce.disable()
  },
  methods: {
    async getTaskDetail() {
      this.Toast.clear()
      this.Toast.allowMultiple()
      this.Toast.loading({
        message: '加载中',
      })
      const res = await getAction('/jeecg-system/admin/taskBaseInfo/queryById', {
        id: this.taskId
      })
      this.Toast.clear()
      if (res.success) {
        this.taskDetail = res.result
        // this.taskDetail.yanhuoFollow = `1、点数抽样\n2、条码扫描\n3、拍摄照片\n4、核对检验清单\n5、提交报告`
        this.taskDetail.title = `${res.result.companyName}-${res.result.yanhuoType_dictText}-${res.result.taskNo}`
        this.$store.state.taskDetail = this.taskDetail
      } else {
        this.Toast.fail(res.message)
      }
    },
  }
}
</script>

<style scoped>

</style>
