<template>
  <van-tab v-bind="$attrs">
    <van-tabs
      v-model:active="activeName"
      color="#055C9D"
      title-active-color="rgba(0, 0, 0, 0.9)"
      title-inactive-color="rgba(0, 0, 0, 0.4)"
      line-width="106"
      @click="handleSwitchTab"
    >
      <TaskInfoTab
        title="基础信息"
        name="a"
        :id="id"
        :task-detail="taskDetail"
      ></TaskInfoTab>
      <task-report-detail-tab
        ref="TaskReportDetailTab"
        title="报告详情"
        name="b"
        :id="id"
        :task-detail="taskDetail"
        @on-refresh="$emit('on-refresh')"
      />
    </van-tabs>
  </van-tab>
</template>

<script>
import TaskInfoTab from "./TaskInfoTab.vue";
import TaskReportDetailTab from "./TaskReportDetailTab";

export default {
  name: "TaskReportTab",
  components: { TaskReportDetailTab, TaskInfoTab },
  props: {
    id: {
      type: String,
      default: "",
    },
    taskDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      activeName: "a",
    };
  },
  methods: {
    handleSwitchTab(val) {
      this.activeName = val;
      if(val=='b') this.$refs.TaskReportDetailTab.setSubmitConclusion()
    },
  },
};
</script>

<style scoped lang="less">
</style>
