<template>
  <van-tab v-bind="$attrs">
    <TaskDetailTabContent :id="id" :task-detail="taskDetail" name="sample"></TaskDetailTabContent>
  </van-tab>
</template>

<script>
import TaskDetailTabContent from './TaskDetailTabContent';
import Card from '@/components/Common/Card.vue'

export default {
  name: "TaskSampleTab",
  components: {TaskDetailTabContent, Card},
  props: {
    id: {
      type: String,
      required: true
    },
    taskDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>

</style>
