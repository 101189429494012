<template>
  <div class="tab-content">
    <van-collapse v-model="activeName">
      <van-collapse-item
        v-for="(order, index) in taskDetail.taskOrders"
        :key="index"
        :name="index"
      >
        <div class="order-item vertical-center" slot="title">
          <img
            :class="{ 'is-active': activeName.includes(index) }"
            src="~@/assets/svg/icon-caret.svg"
            alt=""
          />
          <div class="title">订单号：{{ order.orderNo }}</div>
          <div v-if="order.taskOrderItems" class="num">
            {{ order.taskOrderItems.length }}款
          </div>
          <div v-else></div>
        </div>
        <div
          v-for="(item, index2) in order.taskOrderItems"
          :key="index2"
          class="sub-order space-between"
          @click="handleNavTo(item)"
        >
          <div class="content">
            <div class="name">
              {{ item.singleProductNo }}-{{ item.singleProductName }}
            </div>
            <div>
              <span style="margin-right: 8px"
                >验货数量：{{ item.inspectQuantity }}</span
              >
              <span
                >抽检水平：{{ SAMPLING_LEVEL_DICT[item.samplingLevel] }}</span
              >
            </div>
          </div>
          <div class="icon-wrapper space-between vertical-center">
            <span
              v-if="
                name == 'sample'
                  ? item.actualSampleQuantity
                  : item.status === 'COMPLETED'
              "
              class="finish"
              >完成</span
            >
            <span v-else></span>
            <img src="~@/assets/svg/icon-arrow-right.svg" alt="" />
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { SAMPLING_LEVEL_DICT } from "@/constants";

export default {
  name: "TaskDetailTabContent",
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    taskDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      SAMPLING_LEVEL_DICT,
      activeName: [],
    };
  },
  methods: {
    handleNavTo(item) {
      if (item.status === "COMPLETED"&&this.name != "sample") {
        this.$router.push({
          name: "taskCheck",
          query: {
            ...this.$route.query,
            singleProductId: item.singleProductId,
            orderItemId: item.id,
            step: "4",
          },
        });
      } else {
        this.$router.push({
          name: "taskCheck",
          query: {
            ...this.$route.query,
            singleProductId: item.singleProductId,
            orderItemId: item.id,
            step: this.name == "sample" ? "1" : "2",
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab-content::v-deep {
  padding-top: 16px;
  padding-bottom: 86px;
  box-sizing: border-box;
  height: calc(100vh - 88px);

  .van-collapse-item__title {
    padding: 12px 8px;
  }

  .order-item {
    justify-content: flex-start;
    line-height: 22px;

    img {
      width: 16px;
      height: 16px;

      &.is-active {
        transform: rotate(90deg);
      }
    }

    // .title {
    //   width: 267px;
    // }
    .num {
      flex: 1 1;
      text-align: right;
    }
  }

  .sub-order {
    padding: 4px 8px 4px 40px;
    border-bottom: 1px solid #dfe4eb;

    .content {
      width: calc(100% - 60px);
    }

    .name,
    .finish {
      font-size: 14px;
      line-height: 22px;
      color: rgba(#000, 0.9);
    }

    .icon-wrapper {
      width: 48px;
      flex-shrink: 0;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .van-cell {
    border-bottom: 1px solid #dfe4eb;
  }

  .van-collapse-item__content {
    padding: 0;
  }

  .van-cell::after,
  .van-cell__right-icon {
    display: none;
  }
}
</style>
